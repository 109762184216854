<template>
  <authentic-modal ref="paymentModal" id="payment-modal" :on-submit="submit" :on-cancel="cancel" :size="modalSize"
    :title="modalTitle" :hideFooter="true">
    <div class="container pb-1">
      <div class="row">
        <div class="col-12 col-lg-6 px-0 px-md-4 px-lg-0">
          <div class="row h-100">
            <div class="col">
              <BasicPlan v-if="subscriptionName === 'basic'" :show-submit="false" :modal-type="'billing'" />
              <ProPlan v-if="subscriptionName === 'pro'" :show-submit="false" :modal-type="'billing'" />
              <EnterprisePlan v-if="subscriptionName === 'enterprise'" :show-submit="false" :modal-type="'billing'" />
            </div>
          </div>
        </div>
        <div class="col billing-details">
          <BillingDetails :subscription-type="subscriptionName" :payment-frequency="paymentFrequency"
            :subscription-change="subscriptionChange" />
        </div>
      </div>
    </div>
  </authentic-modal>
</template>

<script setup lang="ts">
  import AuthenticModal from "../shared/AuthenticModal.vue";
  import BillingDetails from "../shared/BillingDetails.vue";

  import BasicPlan from "../shared/plans/Basic.vue";
  import ProPlan from "../shared/plans/Pro.vue";
  import EnterprisePlan from "../shared/plans/Enterprise.vue";

  import PaymentMethod from "../../models/PaymentMethod";
  import Plan from "../../models/Plan";
  import User from "../../models/User";
  import { computed, ref, inject, onBeforeMount, onBeforeUnmount } from "vue";
  import { useStore } from "vuex";

  const paymentModal = ref<typeof AuthenticModal>();
  const $store = useStore();
  const emitter: any = inject("emitter");

  const user = computed((): User => {
    return $store.getters.authUser;
  });

  const modalTitle = "Billing";
  const modalSize = "lg";
  const subscriptionName = ref("");
  const paymentFrequency = ref("");
  const price = ref("");
  const refresh = ref(false);
  const subscriptionChange = ref(false);
  const subscriptionType = ref<Plan | null>(null);

  onBeforeMount(() => {
    emitter.on(
      "payment-modal",
      async (paymentObj: {planType: Plan, upgrade: boolean}) => {
        subscriptionType.value = paymentObj.planType;
        subscriptionName.value = paymentObj.planType.name as string;
        paymentFrequency.value = paymentObj.planType.interval as string;
        price.value = paymentObj.planType.price!.toString();
        paymentObj.upgrade ? (refresh.value = true) : (refresh.value = false);
        subscriptionChange.value = refresh.value;
        paymentModal.value!.showModal();
      }
    );
    emitter.on("close-payment-modal", async () => {
      if (refresh.value) {
        emitter.emit("billing-summary", "refresh");
      }
      paymentModal.value!.hideModal();
    });
  });

  function cancel() {
    paymentModal.value!.hideModal();
  }

  function submit() {
    paymentModal.value!.hideModal();
  }

  onBeforeUnmount(() => {
    emitter.off("payment-modal");
    emitter.off("close-payment-modal");
  });
</script>

<style scoped>
.icon.bi {
  color: #3679e8;
}

.billing-footnotes {
  font-family: "proximanova-regular", sans-serif;
  font-size: 12px;
}

.billing-details {
  padding: 0 0.5rem;
}

@media (max-width: 768px) {
  .billing-details {
    padding: 0 2rem;
  }
}
</style>
