<template>
  <div class="container">
    <div class="row px-0 px-md-2">
      <div class="col-sm-12 col-lg-8 col-xl-4 mx-lg-auto">
        <div class="row h-100">
          <div class="col px-0">
            <BasicPlan :plan-details="basic" :show-submit="true" class="scale-test" />
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-8 col-xl-4 mx-lg-auto">
        <div class="row h-100">
          <div class="col px-0">
            <ProPlan :plan-details="pro" :show-submit="true" class="scale-test" />
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-8 col-xl-4 mx-lg-auto">
        <div class="row h-100">
          <div class="col px-0">
            <EnterprisePlan :plan-details="enterprise" :show-submit="true" class="scale-test" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BasicPlan from "../shared/plans/Basic.vue";
import ProPlan from "../shared/plans/Pro.vue";
import EnterprisePlan from "../shared/plans/Enterprise.vue";
import Plan from "../../models/Plan";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Enterprise from "./plans/Enterprise.vue";

  const $store = useStore();
  
  const plans = computed((): Plan[] => {
    return $store.getters.plans;
  });

  const showSubmit = ref(true);
  const basic = ref<Partial<Plan>>({});
  const pro = ref<Partial<Plan>>({});
  // const business = ref<Partial<Plan>>({});
  const enterprise = ref<Partial<Plan>>({});

  onMounted(() => {
    basic.value = plans.value.filter((plan: Plan) => plan.name === "basic")[0];
    pro.value = plans.value.filter((plan) => plan.name === "pro")[0];
    // business.value = plans.value.filter((plan) => plan.name === "business")[0];
    enterprise.value = plans.value.filter((plan) => plan.name === "enterprise")[0];
  });
</script>

<style scoped>
.border {
  border: 1px solid lightgray;
  border-radius: 0.75rem;
}

.scale-test {
  transform: scale(0.9);
}
</style>
