<template>
    <div class="card business-card">
    <!-- Business Header (Full Width on Mobile) -->
    <div class="row business-header">
      <div class="col col-md-7 text-center sub-spacing">
        <p class="subscription-header">Enterprise</p>
      </div>
    </div>

    <!-- Billing Section -->
    <div v-if="props.modalType === 'billing'" class="row text-center price-section">
      <div class="col">
        <span class="dollar">$</span>
        <span class="subscription-price">{{ enterprise.price || "—" }}</span>
        <span class="subscription-price-text">/ {{ $filters.capitalizeFirst(enterprise.interval as string) }}</span>
      </div>
    </div>

    <!-- Plan Description -->
    <div v-if="props.modalType !== 'billing'" class="row pt-3 plan-text">
      <div class="col text-center">
        <p class="plan-description">
          For organizations that require more dedicated product experiences and support
        </p>
      </div>
    </div>

    <!-- Feature List (Mobile & Desktop Optimized) -->
    <div class="row  pb-4 px-0 px-md-2">
      <div class="col-12">
        <ul class="list-group">
          <li class="list-group-item icon-container"><i class="bi bi-check2 icon"></i> Everything included in Basic and Pro Plus...</li>
          <li class="list-group-item icon-container"><i class="bi bi-check2 icon"></i> Premium Company Categories**</li>
          <li class="list-group-item icon-container"><i class="bi bi-check2 icon"></i> 7 Product Experiences and Codes</li>
          <li class="list-group-item icon-container"><i class="bi bi-check2 icon"></i> Unlimited Product Sections</li>
        </ul>
      </div>
      <div class="col-12">
        <ul class="list-group">
          <li class="list-group-item icon-container"><i class="bi bi-check2 icon"></i> Product Authentication***</li>
          <li class="list-group-item icon-container"><i class="bi bi-check2 icon"></i> Detailed Product Analysis</li>
          <li class="list-group-item icon-container"><i class="bi bi-check2 icon"></i> 30-Minute Strategy Kick-off Call</li>
          <li class="list-group-item icon-container"><i class="bi bi-check2 icon"></i> Dedicated Success Manager</li>
        </ul>
      </div>
    </div>

    <!-- Payment Section -->
    <div v-if="props.showSubmit" class="row px-3 pb-3 align-items-end align-self-center mt-auto">
      <div class="col text-center">
        <payment-choices :subscription-type="(enterprise as any)" :upgrade="props.upgrade"></payment-choices>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { defineProps } from "vue";
import Plan from "../../../models/Plan";
import PaymentChoices from "../PaymentChoices.vue";
import { useStore } from "vuex";

const props = defineProps<{
  planDetails?: Plan;
  showSubmit?: boolean;
  modalType?: string;
  upgrade?: boolean;
}>();

const store = useStore();
const plans = computed(() => store.getters.plans);
const enterprise = ref<Partial<Plan>>({});

onMounted(() => {
  enterprise.value = plans.value.find((plan: { name: string }) => plan.name === "enterprise") || {};
});
</script>

<style scoped>

.icon{
  font-size: 1.5rem;
  margin-right: 5px;
}

.plan-card {
  border-radius: 0.75rem;
}

.icon-container{
  display: flex;
  align-items: center;
}

.business-card {
  /* padding: 1rem; */
  border: 2px solid #898989;
  border-radius: 0.75rem;
}

.sub-spacing {
  padding: 0 12px;
}

/* Subscription Header */
/* .subscription-header {
  font-size: 22px;
  background-color: #074f57;
  color: white;
  padding: 10px;
  border-radius: 0.5rem;
  text-align: center;
} */

.subscription-header {
  font-family: "proximanova-regular", sans-serif;
  font-size: 22px;
  background-color: #074f57;
  color: white;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}

/* Pricing Section */
.price-section {
  margin-bottom: -1rem;
}

.dollar {
  font-size: 2rem;
  vertical-align: top;
  position: relative;
  top: 0.5rem;
  padding-right: 0.2rem;
}

.subscription-price {
  font-size: 48px;
  font-weight: bold;
}

.list-group-item {
  border: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0rem 1rem;
}

/* Remove Border & Extend Header on Mobile */
@media (max-width: 768px) {
  .business-card {
    border: none;
    width: 100%;
    padding: 0;
    /* width: 90%; */
    margin: auto;
  }

  /* Full-width Business Header */
  .business-header .subscription-header {
    border-radius: 0;
    width: 100%;
    margin: 0;
    padding: 12px;
    text-align: center;
  }

  /* Improve Text Spacing */
  .plan-text {
    padding: 0 1rem;
  }

  /* Improve List Readability */
  .list-group-item {
    font-size: 16px;
    padding: 0rem 1rem;
    margin-left: 5px;
    margin-right:5px;
    white-space: normal;
  }

  .sub-spacing {
    padding: 0;
  }


}
</style>
